import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  get(method: string) {
    return this.http.get(environment.expertChatApi + method)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  post(method: string, data: any = {}, headers: any = {}): Observable<any> {
    return this.http.post(environment.expertChatApi + method, data, {
      headers: {
        contentType: 'application/json',
        ...headers
      }
    })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  postWeb2app(method: string, data: any = {}, headers: any = {}) {
    return this.http.post('https://web2app.fx2.io' + method, data, {
      headers: {
        contentType: 'application/json',
        ...headers
      }
    })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  clickHousePost(method: string, formData: FormData): Observable<any> {
    return this.http.post('https://event.fx2.io' + method, formData)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  private errorHandler(error: HttpErrorResponse) {
    return new Observable((subscriber) => {
      subscriber.next(error);
    })
  }
}
