import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {BillingService} from "../../shared/services/billing.service";
import {getSubscriptionInfo, setSubscriptionInfo} from "../actions/subscription.actions";
import {map, mergeMap} from "rxjs/operators";

@Injectable()
export class SubscriptionEffects {
  getSubscriptionInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubscriptionInfo),
      mergeMap(() =>
        this.billingService.getSubscriptionInfo()
          .pipe(
            map((response) =>
              setSubscriptionInfo({
                daysLeft: response.data.web2app.subscription.days_left,
                endDate: response.data.web2app.subscription.stop_ms,
                productName: response.data.web2app.subscription.product_name,
                productPrice: response.data.web2app.subscription.product_price,
                startDate: response.data.web2app.subscription.start_ms,
                cancellationFlow: {
                  regularAttemptUsed: response.data.web2app.cancellation_flow.regular_add_solutions || response.data.web2app.cancellation_flow.regular_discount,
                  trialAttemptUsed: response.data.web2app.cancellation_flow.trial_add_solutions || response.data.web2app.cancellation_flow.trial_discount,
                }
              })
            )
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private billingService: BillingService,
  ) {
  }
}
