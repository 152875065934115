import {ProfileStoreInterface} from "../models/profile-store.interface";
import {CookieService} from "../../shared/services/cookie.service";

export const initialProfileState: ProfileStoreInterface = {
  uuid: CookieService.getCookie('uuid'),
  email: '',
  eventId: '',
  hasOpenTicket: false,
  needConfirmLastTicket: false,
  progressBar: false,
  coinPicker: false,
  needConfirmCloseUnsolvedTicket: false,
  isUserMultiTask: false,
  personalInfo: {
    age: '',
    gender: '',
    math_classes: [],
    name: '',
    telephone: '',
    who_are_you: ''
  }
}
