<div class="add text-start">
  <div class="d-flex align-items-start mb-3">
    <a [href]="getMobileAppUrl" class="d-flex">
      <img src="/assets/mathmaster/cross.svg" alt="cross" *ngIf="isButtonsEnabled">
    </a>
    <img src="/assets/mathmaster/mathmaster-logo.svg" alt="logo" class="mx-2">
    <div>
      <p class="m-0 extra-small lh-1 text-primblack">
        <small>MathMaster</small>
      </p>
      <p class="m-0 lh-1 text-primblack">
        <b>
          <small>Math Solver App</small>
        </b>
      </p>
      <p class="m-0 lh-1 ">
        <small>
          4.6
        </small>
        <img src="/assets/mathmaster/star.svg" alt="">
        <small class="add__text_blue">
          FREE
        </small>
      </p>
    </div>
  </div>
  <a [href]="getMobileAppUrl" class="add_blue w-100 btn rounded-pill py-2" *ngIf="isButtonsEnabled">
    Install
  </a>
</div>
