import { Injectable } from '@angular/core';
// @ts-ignore
import * as io from 'socket.io-client';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: any;
  private eventSocket: any;

  connect() {
    this.socket = io(environment.expertChatSocket);
    this.eventSocket = io(environment.expertChatEventSocket);
  }

  on(event: string): Observable<any> {
    return new Observable((observer: any) => {
      this.socket.on(event, (data: any) => {
        observer.next(data);
      });
    })
  }

  emit(event: string, data: any) {
    this.socket.emit(event, data);
    if (event === 'join') {
      this.eventSocket.emit(event, data);
    }
  }

  disconnect() {
    this.socket.disconnect();
  }
}
