import {createReducer, on} from "@ngrx/store";
import {initialEventVariablesState} from "../state/event-varibles.state";
import {
  setCancellationFlowIsActive,
  setCanFastActivateSubscription,
  setIsFreeUserRegistered, setIsPriorityProgress,
  setIsUserHasMathMasterBanner,
  setIsUserMultitask,
  setWhiteCoinPopup
} from "../actions/event-variables.actions";

export const eventVariablesReducer = createReducer(
  initialEventVariablesState,
  on(
    setCanFastActivateSubscription, (state, action) => ({
      ...state,
      canFastActivateSubscription: action.value
    })
  ),
  on(
    setCancellationFlowIsActive, (state, action) => ({
      ...state,
      cancellationFlowIsActive: action.value
    })
  ),
  on(
    setIsFreeUserRegistered, (state, action) => ({
      ...state,
      isFreeUserRegistered: action.value,
    })
  ),
  on(
    setIsUserMultitask, (state, action) => ({
      ...state,
      isUserMultitask: action.value,
    })
  ),
  on(
    setIsUserHasMathMasterBanner, (state, action) => ({
      ...state,
      isUserHasMathMasterBanner: action.value,
    })
  ),
  on(
    setIsPriorityProgress, (state, action) => ({
      ...state,
      isPriorityProgress: action.value,
    })
  ),
  on(
    setWhiteCoinPopup, (state, action) => ({
      ...state,
      whiteCoinPopup: action.value,
    })
  ),
)
