import {AppStateInterface} from "../state/app.state";
import {createSelector} from "@ngrx/store";
import {BillingStoreInterface} from "../models/billing-store.interface";

export const selectBilling = (state: AppStateInterface) => state.billing;

export const billingSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state,
);

export const autoChargeSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.autoCharge,
);

export const hasSubscriptionSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.hasSubscription,
);

export const isVipSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.isVip,
);

export const userBalanceSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.userBalance,
);

export const userSubscriptionTypeSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.subscriptionType,
);

export const userSolutionsPerDaySelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.coinsPerDay,
);

export const userNextDailyEdgeSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.nextDailyEdge,
);

export const isTrialSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.isTrial,
);

export const processingUuidSelector = createSelector(
  selectBilling,
  (state: BillingStoreInterface) => state.processingUuid,
);

export const isSubscriptionCanceledSelector = createSelector(
  selectBilling,
  (state) => state.isSubscriptionCanceled,
)
