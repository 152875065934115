import {createReducer, on} from "@ngrx/store";
import {initialSubscriptionState} from "../state/subscription.state";
import {setSubscriptionInfo} from "../actions/subscription.actions";

export const subscriptionReducer = createReducer(
  initialSubscriptionState,
  on(setSubscriptionInfo, (_state, action) => ({
    ...action
  })),
)
