import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {map, mergeMap} from "rxjs/operators";
import {getBillingInfo, setBillingInfo} from "../actions/billing.actions";
import {BillingService} from "../../shared/services/billing.service";

@Injectable()
export class BillingEffects {
  getBillingInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillingInfo),
      mergeMap(() =>
        this.billingService.getUserBillingStatus()
          .pipe(
            map((response) =>
              setBillingInfo({
                userBalance: response.data.balance.solutions,
                isVip: response.data.subscription.web2app.priority.is_active,
                hasSubscription: response.data.subscription.web2app.is_active,
                autoCharge: response.data.payment_config.web2app.auto_charge,
                subscriptionType: response.data.subscription.web2app.daily_solution ? 'regular' : 'onetime',
                coinsPerDay: response.data.subscription.web2app.daily_solution,
                nextDailyEdge: response.data.subscription.web2app.next_daily_edge_ms ? response.data.subscription.web2app.next_daily_edge_ms : 0,
                isTrial: response.data.subscription.web2app.is_trial,
                processingUuid: response.data.payment_config.web2app.account_uuid,
                isSubscriptionCanceled: !!response.data.subscription.web2app.cancel_at
              })
            ),
          )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private billingService: BillingService
  ) {
  }
}
