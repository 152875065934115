import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Store} from "@ngrx/store";
import {AppStateInterface} from "../../store/state/app.state";
import {uuidSelector} from "../../store/selectors/profile.selectors";
import {Observable, Subject} from "rxjs";
import {ChatMessageInterface} from "../../modules/chat/multi-chat/models/chat-message.interface";
import {TicketInfoInterface} from "../../modules/chat/multi-chat/models/ticket-info.interface";

interface GetTicketsSuccessResponseInterface {
  "status": string,
  "data": {
    "statuses": [
      "closed",
      "locked",
      "opened"
    ],
    "priorities": [
      "normal",
      "high",
      "very_high"
    ],
    "tickets_count": number,
    "show_more": boolean,
    "tickets": Array<TicketInfoInterface>
  }
}

interface GetMessagesSuccessResponseInterface {
  "status": string,
  "data": {
    "tickets_count": number,
    "closed_tickets_count": number,
    "has_open_ticket": boolean,
    "show_more": boolean,
    "comments": Array<ChatMessageInterface>
  }
}

@Injectable({
  providedIn: 'any'
})
export class MultiChatService {

  userOutFromTicket$: Subject<boolean> = new Subject<boolean>();

  private uuid: string = '';

  constructor(
    private api: ApiService,
    private store: Store<AppStateInterface>
  ) {
    this.store.select(uuidSelector)
      .subscribe((uuid) => {
        this.uuid = uuid;
      })
  }

  /**
   * @param isUserMultitask - 1 or 0
   */
  setUserMultiTask(isUserMultitask: number): Observable<{ status: string }> {
    return this.api.post('/api/user/multi-task', {
      is_multitask: isUserMultitask,
    }, {
      Authorization: this.uuid
    });
  }

  askQuestion({ticket_id = null, text = undefined, file = undefined}: any) {
    return this.api.post('/api/ticket/send/ask/v3', {
      ticket_id,
      text,
      file,
    }, {
      Authorization: this.uuid
    })
  }

  getTickets(from: any = undefined, limit: number = 20, ticket_ids: number | Array<number> | undefined = undefined): Observable<GetTicketsSuccessResponseInterface> {
    let requestObject: any;
    if (Array.isArray(ticket_ids)) {
      requestObject = new FormData();
      ticket_ids.forEach((el) => {
        requestObject.append('ticket_ids[]', el)
      })
    } else {
      requestObject = {
        from,
        limit,
        ticket_id: ticket_ids
      }
    }
    return this.api.post('/api/ticket/user', requestObject, {
      Authorization: this.uuid
    })
  }

  getMessagesFromTicket(ticket_id: any = undefined, from: any = undefined, limit: any = 20): Observable<GetMessagesSuccessResponseInterface> {
    return this.api.post('/api/ticket/show/v3', {
      ticket_id,
      limit,
      from,
      test_attach: 1,
    }, {
      Authorization: this.uuid
    })
  }

  sendTextMessageToTicket(body: string, reply_id: any = undefined, comment_answer: any = undefined, ticket_id: any = undefined) {
    return this.api.post('/api/ticket/send/v2', {
      body,
      reply_id,
      comment_answer,
      ticket_id,
      platform: 'web',
    }, {
      Authorization: this.uuid
    })
  }

  sendFileMessageToTicket(file: any, replyMsgId: any = '', comment_answer: any = '', ticket_id: number = 0) {
    const fileReqData = new FormData();
    fileReqData.append('file', file);
    if (replyMsgId) {
      fileReqData.append('reply_id', replyMsgId);
    }
    if (ticket_id) {
      fileReqData.append('ticket_id', `${ticket_id}`);
    }
    if (comment_answer) {
      fileReqData.append('comment_answer', comment_answer);
    }
    fileReqData.append('platform', 'web');
    return this.api.post('/api/ticket/send/file/v2', fileReqData, {
      Authorization: this.uuid
    });
  }

  // status: ['yes', 'no']
  setTicketStatusByUser(answer: string, ticket_id: number) {
    return this.api.post('/api/ticket/need-unsolved-close/v3', {
      answer,
      ticket_id
    }, {
      Authorization: this.uuid
    });
  }

  closeTicket(ticket_id: number) {
    return this.api.post('/api/ticket/need-close/v3', {
      ticket_id
    }, {
      Authorization: this.uuid
    });
  }
}
