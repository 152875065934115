import {createAction, props} from "@ngrx/store";

export enum ExpertActions {
  SetExpertName = '[Expert] Set Expert Name',
  SetExpertImg = '[Expert] Set Expert img',
  SetExpertTypingStatus = '[Expert] Set Expert Typing Status',
}

export const setExpertName = createAction(
  ExpertActions.SetExpertName,
  props<{ name: string }>()
);

export const setExpertImg = createAction(
  ExpertActions.SetExpertImg,
  props<{ img: string }>()
);

export const expertTyping = createAction(
  ExpertActions.SetExpertTypingStatus,
  props<{ isTyping: boolean }>()
);
