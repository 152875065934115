import {createAction, props} from "@ngrx/store";

export enum EventVariablesActions {
  SetFastSubActive = '[Test Variables] Set Fast Subscription Active Variable',
  SetIsCancellationFlowActive = '[Test Variables] Set Cancellation Flow',
  SetIsFreeUserRegistered = '[Test Variables] Set Is Free User Registered',
  SetIsUserMultitask = '[Test Variables] Set Is User Multitask',
  SetIsUserHasMathMasterBanner = '[Test Variables] Set Is User Has MathMaster Banner',
  SetIsPriorityProgress = '[Test Variables] Set Is User Has Priority Btn In ProgressBar',
  SetWhiteCoinPopup = '[Test Variables] Set White Coin Popup',
}

export const setCanFastActivateSubscription = createAction(
  EventVariablesActions.SetFastSubActive,
  props<{value: boolean}>()
);

export const setCancellationFlowIsActive = createAction(
  EventVariablesActions.SetIsCancellationFlowActive,
  props<{value: boolean}>()
);

export const setIsFreeUserRegistered = createAction(
  EventVariablesActions.SetIsFreeUserRegistered,
  props<{value: boolean}>()
);

export const setIsUserMultitask = createAction(
  EventVariablesActions.SetIsUserMultitask,
  props<{value: boolean}>()
);

export const setIsUserHasMathMasterBanner = createAction(
  EventVariablesActions.SetIsUserHasMathMasterBanner,
  props<{value: boolean}>()
);

export const setIsPriorityProgress = createAction(
  EventVariablesActions.SetIsPriorityProgress,
  props<{value: boolean}>()
);

export const setWhiteCoinPopup = createAction(
  EventVariablesActions.SetWhiteCoinPopup,
  props<{value: boolean}>()
);
