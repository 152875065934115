import {Component, Input, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppStateInterface} from "../../../store/state/app.state";
import {eventIdSelector} from "../../../store/selectors/profile.selectors";

@Component({
  selector: 'app-mathmaster-card',
  templateUrl: './mathmaster-card.component.html',
  styleUrls: ['./mathmaster-card.component.scss']
})
export class MathmasterCardComponent implements OnInit {

  @Input() isButtonsEnabled: boolean = true;
  private eventId: string = '';

  constructor(
    private store: Store<AppStateInterface>
  ) { }

  ngOnInit(): void {
    this.store.select(eventIdSelector)
      .subscribe((eventId) => {
        this.eventId = eventId;
      })
  }

  get getMobileAppUrl(): string {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return `https://z0s2x.app.link/PMwURiPiYxb?eventid=${this.eventId}`;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return `https://mathmaster.onelink.me/Vtkz/q9abksfx?eventid=${this.eventId}`;
    }
    return "#";
  }

}
