import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./shared/guards/auth.guard";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import ('./modules/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'legal',
    loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule),
  },
  {
    path: 'cancellation',
    loadChildren: () => import('./modules/cancellation/cancellation.module').then(m => m.CancellationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'su',
    loadChildren: () => import('./modules/additional/additional.module').then(m => m.AdditionalModule),
  },
  {
    path: 'vip',
    loadChildren: () => import('./modules/vip/vip.module').then((m) => m.VipModule),
  },

  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
