import {Component} from '@angular/core';
import {combineLatest} from "rxjs";
import {ClickhouseService} from "./shared/services/clickhouse.service";
import {SocketService} from "./shared/services/socket.service";
import {Store} from "@ngrx/store";
import {AppStateInterface} from "./store/state/app.state";
import {eventIdSelector, uuidSelector} from "./store/selectors/profile.selectors";
import {
  setCancellationFlowIsActive,
  setCanFastActivateSubscription,
  setIsFreeUserRegistered, setIsPriorityProgress, setIsUserHasMathMasterBanner, setWhiteCoinPopup
} from "./store/actions/event-variables.actions";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private clickHouse: ClickhouseService,
    private socketService: SocketService,
    private store: Store<AppStateInterface>,
  ) {
  }

  public ngOnInit(): void {
    this.socketService.connect();
    this.socketService.on('connect')
      .subscribe(() => {
        combineLatest([
          this.store.select(uuidSelector),
          this.store.select(eventIdSelector),
        ])
          .subscribe(([uuid, eventId]) => {
            if (uuid && eventId) {
              this.socketService.emit('join', {
                type: 'web',
                channel: uuid,
                eventId: eventId,
              });
              combineLatest([
                this.clickHouse.showEvent('abtestcancelflow'),
                this.clickHouse.showEvent('free_user_registered'),
                this.clickHouse.showEvent('abtestupgradesub'),
                this.clickHouse.showEvent('user_adbanner'),
                this.clickHouse.showEvent('user_priorityprogress'),
                this.clickHouse.showEvent('user_whitecoinpopup'),
              ])
                .subscribe(([abtestcancelflow, isUserFree, abtestupgradesub, adBanner, priorityprogress, whitecoinpopup]) => {
                  this.store.dispatch(setCancellationFlowIsActive({value: abtestcancelflow.data?.subevents.length ? abtestcancelflow.data.subevents[0].value === 'true' : false}));
                  this.store.dispatch(setIsFreeUserRegistered({value: isUserFree.data?.subevents.length ? isUserFree.data.subevents[0].value === 'true' : false}));
                  this.store.dispatch(setCanFastActivateSubscription({value: abtestupgradesub.data?.subevents.length ? abtestupgradesub.data.subevents[0].value === 'true' : false}))
                  this.store.dispatch(setIsUserHasMathMasterBanner({value: adBanner.data?.subevents.length ? adBanner.data.subevents[0].value === 'adbanner' : false}))
                  this.store.dispatch(setIsPriorityProgress({value: priorityprogress.data?.subevents.length ? priorityprogress.data.subevents[0].value === 'priorityprogress' : false}))
                  this.store.dispatch(setWhiteCoinPopup({value: whitecoinpopup.data?.subevents.length ? whitecoinpopup.data.subevents[0].value === 'whitecoinpopup' : false}))
                })
            }
          });
      })
  }
}
