import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map, tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {AppStateInterface} from "../../store/state/app.state";
import {uuidSelector} from "../../store/selectors/profile.selectors";
import {getProfile, setProfile, setUuid} from "../../store/actions/profile.actions";
import {Observable} from "rxjs";
import {getBillingInfo} from "../../store/actions/billing.actions";

export interface ProfileResponseInterface {
  data: {
    profile: {
      age: string,
      gender: string,
      math_classes: Array<string>,
      name: string,
      telephone: string,
      who_are_you: string,
    }
    user: {
      attached_socials: Array<{
        key: string;
        token: string;
        value: string;
      }>;
      created_at: number;
      email: string;
      event_id: string;
      first_name: string;
      has_open_ticket: boolean;
      id: number;
      img: string;
      last_name: string;
      name: string;
      need_confirm_close_last_ticket: boolean;
      need_confirm_close_unsolved_ticket: boolean,
      progress_bar_version: string;
      referral: boolean;
      referral_str: string;
      service_name: string;
      uuid: string;
      uuid_public: number;
      popup_coin_picker: boolean,
      is_multitask: boolean,
    }
  },
  status: string,
}

@Injectable({
  providedIn: 'any',
})
export class AuthService {

  private uuid: string = ''

  constructor(
    private api: ApiService,
    private store: Store<AppStateInterface>,
  ) {
    this.store.select(uuidSelector)
      .subscribe((data) => {
        this.uuid = data;
      });
  }

  login(email: string, password: string) {
    return this.api.post('/api/user/auth', {
      email: email,
      password: password,
      platform: 'web',
    })
      .pipe(
        map((resp: any) => {
          if (resp.status === 'success') {
            this.store.dispatch(setUuid({uuid: resp.data.user_uid}));
          } else {
            this.store.dispatch(setUuid({uuid: ''}));
          }
          return resp;
        })
      )
  }

  getProfileData(uuid = ''): Observable<ProfileResponseInterface> {
    return this.api.post('/api/user/profile/show', {}, {
      Authorization: this.uuid || uuid,
    })
      .pipe(
        tap((response: ProfileResponseInterface) => {
          if (response.status === 'success') {
            this.store.dispatch(setProfile({
              uuid: response.data.user.uuid,
              email: response.data.user.email,
              eventId: response.data.user.event_id,
              progressBar: response.data.user.progress_bar_version === 'on',
              hasOpenTicket: response.data.user.has_open_ticket,
              needConfirmLastTicket: response.data.user.need_confirm_close_last_ticket,
              coinPicker: response.data.user.popup_coin_picker,
              needConfirmCloseUnsolvedTicket: response.data.user.need_confirm_close_unsolved_ticket,
              personalInfo: response.data.profile,
              isUserMultiTask: response.data.user.is_multitask,
            }));
          }
        }),
      );
  }

  setPersonalInfo(info: {
    first_name: string,
    telephone: string,
    gender: string,
    age: string,
    who_are_you: string,
    math_classes: string,
  }) {
    return this.api.post('/api/user/profile/update/marketing', info, {
      Authorization: this.uuid,
    })
      .pipe(
        tap(response => {
          if (response.status === 'success') {
            this.store.dispatch(getProfile());
            this.store.dispatch(getBillingInfo());
          }
        })
      );
  }

  forgotPassword(email: string) {
    return this.api.post('/api/user/forget', {
      email: email
    })
  }

}
