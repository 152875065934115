import {createReducer, on} from "@ngrx/store";
import {initialSystemState} from "../state/system.state";
import {toggleMobileMenu} from "../actions/system.actions";

export const systemReducer = createReducer(
  initialSystemState,
  on(toggleMobileMenu, (state) => ({
    ...state,
    isMobileMenuShown: !state.isMobileMenuShown
  })),
)
