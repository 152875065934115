import {EventVariablesStoreInterface} from "../models/event-variables-store.interface";

export const initialEventVariablesState: EventVariablesStoreInterface = {
  canFastActivateSubscription: false,
  cancellationFlowIsActive: false,
  isFreeUserRegistered: false,
  isUserHasMathMasterBanner: false,
  isPriorityProgress: false,
  whiteCoinPopup: false,
};
