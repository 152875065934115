import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MathmasterCardComponent} from "./mathmaster-card.component";


@NgModule({
  declarations: [
    MathmasterCardComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MathmasterCardComponent
  ]
})
export class MathmasterCardComponentModule {
  static getComponent() {
    return MathmasterCardComponent;
  }
}
