import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavigationComponent} from "./navigation.component";
import {RouterModule} from "@angular/router";
import {MathmasterCardComponentModule} from "../../other/mathmaster-card/mathmaster-card-component.module";


@NgModule({
  declarations: [
    NavigationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MathmasterCardComponentModule
  ],
  exports: [
    NavigationComponent,
  ]
})
export class NavigationComponentModule { }
